import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useAuth } from '../providers';
import { useNavigate } from 'react-router-dom';
import '../styles/ProfileDetails.css';
import { useMediaQuery } from 'react-responsive';
import ChatBot from '../components/ChatBot';

const API_ROOT = "https://medharbor-backend.onrender.com"; 


export const fetchProfileDetails = async (userId) => {
    try {
        const response = await fetch(`${API_ROOT}/profile/${userId}`);
        // const response = await fetch(`http://localhost:4000/profile/${userId}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch profile details: ${response.statusText}`);
        }
        const data = await response.json();
        console.log("Profile data:", data);
        return data;
    } catch (error) {
        console.error("Failed to fetch profile details:", error);
        throw error;
    }
};

function ProfileDetails() {
    const auth = useAuth();
    const navigate = useNavigate();
    const [profile, setProfile] = useState(null);
    const [showQuestionnaire, setShowQuestionnaire] = useState(false);
    const [symptoms, setSymptoms] = useState([]);
    const [displaySymptoms, setDisplaySymptoms] = useState(false);
    const userId = auth.user?.id;
    const [twoWeeksProblems, setTwoWeeksProblems] = useState({});
    const [fourWeeksProblems, setFourWeeksProblems] = useState({});

    

    const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)'});

    const symptomLabels = {
        "movingSpeaking": "Moving or speaking slowly or fidgety",
        "thoughts": "Thoughts of self-harm",
        "troubleConcentrating": "Trouble concentrating",
        "feelingBad": "Feeling bad about yourself",
        "poorAppetite": "Poor appetite or overeating",
        "feelingTired": "Feeling tired or having little energy",
        "troubleSleep": "Trouble sleeping",
        "feelingDown": "Feeling down, depressed, or hopeless",
        "littleInterest": "Little interest or pleasure in doing things",
        "stomachPain": "Stomach pain",
        "backPain": "Back pain",
        "jointPain": "Joint pain",
        "menstrualCramps": "Menstrual cramps",
        "headaches": "Headaches",
        "chestPain": "Chest pain",
        "dizziness": "Dizziness",
        "faintingSpells": "Fainting spells",
        "heartPound": "Heart pounding",
        "shortnessBreath": "Shortness of breath",
        "constipation": "Constipation",
        "nausea": "Nausea"
    };
    
    const valueLabels = {
        "notAtAll": "Not at all",
        "severalDays": "Several days",
        "moreThanHalfTheDays": "More than half the days",
        "nearlyEveryDay": "Nearly every day",
        "notBothered": "Not bothered",
        "botheredALittle": "Bothered a little",
        "botheredALot": "Bothered a lot"
    };
    

    const fetchSymptoms = async (userId) => {
        try {
            const response = await fetch(`${API_ROOT}/profile/${userId}/symptoms`);
            if (!response.ok) {
                throw new Error(`Failed to fetch symptoms: ${response.statusText}`);
            }
            const data = await response.json();
            if (Object.keys(data).length > 0) {
                setSymptoms(data);
                setShowQuestionnaire(false);
                setDisplaySymptoms(true);
            }
            else {
                setSymptoms(null);
            }
        } catch (error) {
            console.error("Failed to fetch symptoms:", error);
            throw error;
        }
    };

    useEffect(() => {
        const loadProfile = async () => {
            if (!auth || !auth.user || !auth.user.id) {
                console.error("User not authenticated or user ID not available");
                return;
            }
            try {
                const data = await fetchProfileDetails(userId);
                console.log("Data before setProfile:",data);
                setProfile(data);
            } catch (error) {
                console.error("Failed to load profile:", error);
            }
        };
            
        loadProfile();

        if(userId) {
            fetchSymptoms(userId);
        }
    }, [auth, userId]);

    useEffect(() => {
        if (profile) {
          console.log("Data after setProfile:", profile);
        }
      }, [profile]);

    const handleEditClick = () => {
        navigate('/ProfileUpdate');
    };

    if (!profile) {
        return <div>Loading...</div>;
    }

    const containerStyle = {
        position: 'relative',
        paddingTop: isSmallScreen ? '25px' : '75px',
        display: 'flex',
        flexDirection: 'column',
        //alignItems: 'center',
        margin: '0 auto',
    };

    const boxStyle = {
        backgroundColor: '#f8f9fa',
        padding: '16px',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        width: '100%',
        alignItems: 'center'
    };

    const handleSubmit = async (e, userID) => {

        //Submit the form only if all the questions are answered
        const isFormComplete = () => {
            const twoWeeksCount = Object.keys(twoWeeksProblems).length;
            const fourWeeksCount = Object.keys(fourWeeksProblems).length;
            return twoWeeksCount === 9 && fourWeeksCount === 12;
        };
        
        
        if (!isFormComplete()) {
            alert('Please answer all questions for both 2 weeks and 4 weeks.');
            return;
        }

        const profileData = {
            userId: userID,
            twoWeeksProblems,
            fourWeeksProblems
        };

        try {
            const response = await fetch(`${API_ROOT}/profile/questionnaire`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(profileData),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const result = await response.json();
            console.log('Success:', result);
            fetchSymptoms(userID);


        }      
        catch (error) {
            console.error("Failed to submit symptoms:", error);
            throw error;
        }
    }; 

    const handleChange = (e) => {
        const { name, value } = e.target;
        const period = e.target.dataset.period;

        if (period === 'twoWeeks') {
            setTwoWeeksProblems(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else if (period === 'fourWeeks') {
            setFourWeeksProblems(prevState => ({
                ...prevState,
                [name]: value
            }));
         }
    };

    return (
        <Container className="mt-5 profile-container" style={containerStyle}>
            <Row>
                <Col xs={12} md={{ span: 8, offset: 2 }}>
                    <div style={boxStyle}>
                        <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
                            <h1 className="mb-2 mb-md-0">
                                {auth.user?.firstName} {auth.user?.lastName}
                            </h1>
                            <Button
                                variant="primary"
                                onClick={handleEditClick}
                                style={{ backgroundColor: 'rgb(45, 44, 56)', borderColor: 'rgb(45, 44, 56)' }}
                            >
                                Edit
                            </Button>
                        </div>
                        <p><strong>Date Of Birth:</strong> {profile[0].myDateOfBirth|| ''}</p>
                        <p><strong>Gender:</strong> {profile[0].gender || ''}</p>
                        <p><strong>Phone Number:</strong> {profile[0].phoneNumber || ''}</p>
                        <p><strong>Email:</strong> {profile[0].email || ''}</p>
                        <p><strong>Address:</strong> {profile[0].myAddress || ''}</p>
                    </div>
                     {displaySymptoms ? (
                        <Container className='symptoms-container'>
                        <h1> Symptoms </h1>
                        <section class="symptom-section">
                            <h3> 2 Weeks Symptoms </h3>
                            <ul> 
                                {Object.entries(symptoms.twoWeeksProblems).map(([key, value]) => (
                                    <li key={key}>
                                        <strong>{symptomLabels[key]}:</strong> {valueLabels[value]}
                                    </li>
                                ))}
                            </ul> 
                        </section>

                        <section className="symptom-section">
                            <h3> 4 Weeks Symptoms </h3>
                            <ul> 
                                {Object.entries(symptoms.fourWeeksProblems).map(([key, value]) => (
                                    <li key={key}>
                                        <strong>{symptomLabels[key]}:</strong> {valueLabels[value]}
                                    </li>
                                ))}
                            </ul>
                        </section>
                        </Container>
                    ) : (
                     showQuestionnaire ? (
                        <div className="questionnaire-box">
                            <h3>During the last 4 weeks, how much have you been bothered by any of the following problems?</h3>
                            <table className="question-table">
                                <thead>
                                    <tr>
                                        <th>Problem</th>
                                        <th>Not bothered</th>
                                        <th>Bothered a little</th>
                                        <th>Bothered a lot</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Stomach pain</td>
                                        <td><input type="radio" name="stomachPain" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="stomachPain" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="stomachPain" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Back pain</td>
                                        <td><input type="radio" name="backPain" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="backPain" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="backPain" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Joints pain</td>
                                        <td><input type="radio" name="jointPain" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="jointPain" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="jointPain" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Menstrual cramps</td>
                                        <td><input type="radio" name="menstrualCramps" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="menstrualCramps" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="menstrualCramps" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Headaches</td>
                                        <td><input type="radio" name="headaches" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="headaches" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="headaches" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Chest pain</td>
                                        <td><input type="radio" name="chestPain" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="chestPain" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="chestPain" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Dizziness</td>
                                        <td><input type="radio" name="dizziness" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="dizziness" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="dizziness" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Fainting spells</td>
                                        <td><input type="radio" name="faintingSpells" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="faintingSpells" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="faintingSpells" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Heart pounding</td>
                                        <td><input type="radio" name="heartPound" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="heartPound" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="heartPound" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Shortness of breath</td>
                                        <td><input type="radio" name="shortnessBreath" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="shortnessBreath" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="shortnessBreath" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Constipation</td>
                                        <td><input type="radio" name="constipation" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="constipation" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="constipation" value="botheredALot" data-period="fourWeeks"  onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Nausea</td>
                                        <td><input type="radio" name="nausea" value="notBothered" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="nausea" value="botheredALittle" data-period="fourWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="nausea" value="botheredALot" data-period="fourWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                </tbody>
                            </table>

                            <h3>Over the last 2 weeks, how often have you been bothered by any of the following problems?</h3>
                            <table className="question-table">
                                <thead>
                                    <tr>
                                        <th>Problem</th>
                                        <th>Not at all</th>
                                        <th>Several days</th>
                                        <th>More than half the days</th>
                                        <th>Nearly every day</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>Little interest or pleasure in doing things</td>
                                        <td><input type="radio" name="littleInterest" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="littleInterest" value="severalDays" data-period="twoWeeks"  onChange={handleChange} required/></td>
                                        <td><input type="radio" name="littleInterest" value="moreThanHalfTheDays" data-period="twoWeeks"  onChange={handleChange} required/></td>
                                        <td><input type="radio" name="littleInterest" value="nearlyEveryDay" data-period="twoWeeks"  onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Feeling down, depressed, or hopeless</td>
                                        <td><input type="radio" name="feelingDown" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingDown" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingDown" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingDown" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Trouble falling or staying asleep, or sleeping too much</td>
                                        <td><input type="radio" name="troubleSleep" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="troubleSleep" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="troubleSleep" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="troubleSleep" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Feeling tired or having little energy</td>
                                        <td><input type="radio" name="feelingTired" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingTired" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingTired" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingTired" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Poor appetite or overeating</td>
                                        <td><input type="radio" name="poorAppetite" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="poorAppetite" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="poorAppetite" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="poorAppetite" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Feeling bad about yourself — or that you are a failure or have let yourself or your family down</td>
                                        <td><input type="radio" name="feelingBad" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingBad" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingBad" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="feelingBad" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Trouble concentrating on things, such as reading the newspaper or watching television</td>
                                        <td><input type="radio" name="troubleConcentrating" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="troubleConcentrating" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="troubleConcentrating" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="troubleConcentrating" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Moving or speaking so slowly that other people could have noticed? Or the opposite — being so fidgety or restless that you have been moving around a lot more than usual</td>
                                        <td><input type="radio" name="movingSpeaking" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="movingSpeaking" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="movingSpeaking" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="movingSpeaking" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                    <tr>
                                        <td>Thoughts that you would be better off dead or of hurting yourself in some way</td>
                                        <td><input type="radio" name="thoughts" value="notAtAll" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="thoughts" value="severalDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="thoughts" value="moreThanHalfTheDays" data-period="twoWeeks" onChange={handleChange} required/></td>
                                        <td><input type="radio" name="thoughts" value="nearlyEveryDay" data-period="twoWeeks" onChange={handleChange} required/></td>
                                    </tr>
                                </tbody>
                            </table>

                            <Button className="btn-submit" onClick={(e) => {handleSubmit(e, userId)}}>Submit</Button>
                        </div>
                    ) : (
                        <div className="initial-prompt" style={{marginTop: '10%'}}>
                            <h3>Would you be willing to answer a few health-related questions?</h3>
                            <div>
                                <Button className="btn-question" onClick={() => setShowQuestionnaire(true)}>Yes</Button>
                            </div>
                        </div>
                    )
                    )}

                </Col>
            </Row>
            {/* ChatBot Component */}
      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000
      }}>
        <ChatBot />
      </div>
        </Container>
    );
}

export default ProfileDetails;
