import Conversation from '../components/conversation';
import Message from '../components/message';
import '../styles/messenger.css';
import { useAuth } from '../providers';
import { useEffect, useState } from 'react';

export default function Messenger() {
    const auth = useAuth();
    const userId = auth.user?.id;
    const [conversations, setConversations] = useState([]);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [selectedConversation, setSelectedConversation] = useState(null);

    useEffect(() => {
        if (userId) {
            fetchConversations();
        }
    }, [userId]);

    const fetchConversations = async () => {
        try {
            const response = await fetch(`https://medharbor-backend.onrender.com/users`);
            const data = await response.json();
            if (data && data.length > 0) {
                setConversations(data);
            }
        } catch (err) {
            console.error('Failed to fetch conversations', err);
        }
    };

    const fetchMessages = async (userIdFromCONV) => {
        try {
            const response = await fetch(
                `https://medharbor-backend.onrender.com/messages/${userIdFromCONV}`
            );
            const data = await response.json();
            setMessages(data);
        } catch (err) {
            console.error('Failed to fetch messages', err);
        }
    };

    const handleUserClickedEvent = (conv) => {
        if (conv && conv._id) {
            setSelectedConversation(conv);
            fetchMessages(conv._id);
        }
    };

    const handleSend = async () => {
        if (!newMessage || !selectedConversation) return;

        const messageData = {
            senderId: userId,
            receiverId: selectedConversation._id,
            message: newMessage,
        };

        try {
            const response = await fetch('https://medharbor-backend.onrender.com/messages', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(messageData),
            });

            if (!response.ok) {
                throw new Error('Failed to send message');
            }

            const data = await response.json();
            setMessages((prevMessages) => [...prevMessages, data]);
            setNewMessage('');
        } catch (err) {
            console.error('Failed to send message', err);
        }
    };

    return (
        <div className="messenger">
            <div className="chatMenu">
                <div className="chatMenuWrapper">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="chatMenuInput"
                    />
                    {conversations.map((conv) => (
                        <Conversation
                            key={conv._id}
                            conversation={conv}
                            userClicked={handleUserClickedEvent}
                        />
                    ))}
                </div>
            </div>
            <div
                className="chatBox"
                style={{ boxShadow: 'rgba(0, 0, 0, 0.16) 0px -1px 4px' }}
            >
                <div className="chatBoxWrapper">
                    <div
                        className="chatBox"
                        style={{
                            boxShadow: 'rgba(0, 0, 0, 0.16) 0px -1px 4px',
                        }}
                    >
                        <div className="chatBoxWrapper">
                            <div
                                className="chatBoxTop"
                                style={{
                                    minHeight: '480px',
                                    position: 'relative',
                                }}
                            >
                                {messages.length === 0 ? (
                                    <div
                                        className="noMessages"
                                        style={{
                                            textAlign: 'center',
                                            color: '#999',
                                            fontStyle: 'italic',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            transform: 'translate(-50%, -50%)',
                                        }}
                                    >
                                        <p>
                                            No messages yet. Go ahead and send a
                                            text!
                                        </p>
                                    </div>
                                ) : (
                                    messages.map((msg) => (
                                        <Message
                                            key={msg._id}
                                            message={msg.message}
                                            own={msg.senderId === userId}
                                            timestamp={new Date(
                                                msg.timestamp
                                            ).toLocaleTimeString()}
                                            profilePic="https://icons.veryicon.com/png/o/internet--web/55-common-web-icons/person-4.png"
                                        />
                                    ))
                                )}
                            </div>

                            <div className="chatBoxBottom">
                                <textarea
                                    placeholder="Write something...."
                                    className="chatMessageInput"
                                    value={newMessage}
                                    onChange={(e) =>
                                        setNewMessage(e.target.value)
                                    }
                                />
                                <button
                                    className="chatSubmitButton"
                                    onClick={handleSend}
                                >
                                    Send
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}
