import "../styles/message.css";

export default function Message({ message, own, timestamp, profilePic }) {
    return (
        <div className={own ? 'message own' : 'message'}>
            <div className="messageTop">
                <img
                    src={profilePic || "https://icons.veryicon.com/png/o/internet--web/55-common-web-icons/person-4.png"}
                    className="messageImg"
                    alt="user profile"
                />
                <p className="messageText">{message}</p>
            </div>
            <div className="messageBottom">{timestamp}</div>
        </div>
    );
}
