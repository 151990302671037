

import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import newsLetterData from '../Assets/data/newsletters.json';
import { useMediaQuery } from 'react-responsive'
import '../styles/NL.css';
import '../styles/Newsletter.css';
import '../styles/Home.css';


function NL({pageType}) {

  const [currentPage, setCurrentPage] = useState(1);
  const [blogs, setBlogs] = useState([]);
  const [expandedBlog, setExpandedBlog] = useState(null);
  const [totalPages, setTotalPages] = useState(3);
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })


  useEffect(() => {
    getFirstNewsletters(1, 2);
  }, []);

  const getFirstNewsletters = async (page = 1, limit = 2) => {
    const data = newsLetterData[page];
    setBlogs(data);
    const noOfPages = Object.keys(newsLetterData).length;
    console.log(noOfPages);
    setCurrentPage(page);
    setTotalPages(noOfPages);

    // try {
    //   const response = await fetch(`http://localhost:4000/newsletters?page=${page}&limit=${limit}`);
    //   if (!response.ok) {
    //     throw new Error('Network response was not ok');
    //   }
    //   const result = await response.json();
    //   console.log("newsletter: ", result);
    //   setBlogs(result.newsLetterMessages);
    //   setTotalPages(result.totalPages);
    // } catch (error) {
    //   console.error("Error fetching newsletters:", error);
    // }
  };

  const getNewsletters = async (page = 1, limit = 2) => {
    getFirstNewsletters(page , 2);
    // try {
    //   const response = await fetch(`http://localhost:4000/newsletters?page=${page}&limit=${limit}`);
    //   console.log("data fetched");
    //   const result = await response.json();
    //   console.log("newsletter: ", result);
    //   setBlogs(result.newsLetterMessages);
    // } catch (error) {
    //   console.error("Error fetching newsletters:", error);
    // }
  };

  const togglePage = (page) => {
    setCurrentPage(page);
    getNewsletters(page, 2);
  };

  const toggleExpandedBlog = (blog) => {
    setExpandedBlog(blog === expandedBlog ? null : blog);
  };

  useEffect(() => {
    if (expandedBlog) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [expandedBlog]);

  return (
    <div className="App">
      <main style={{ backgroundColor: '#FFFFFF' }}>
        <div style={{}} />
        <section style={{ paddingRight: 0 }}>
          <div
            className="container rounded py-5"
            style={{
              marginBottom: '-23px',
              paddingBottom: 0,
              backgroundColor: '#F5F7F8',
            }}
          >
            <div className="row">
              <div className="col-md-8 col-xl-6 text-center mx-auto">
                <p className="fw-bold mb-2" style={{ color: '#28A745', fontSize: '16px' }}>
                  Trending
                </p>
                <h3 className="fw-bold" style={{ color: '#082144' }}>
                  Latest Blogs
                </h3>
              </div>
            </div>
            {blogs.length > 0 ? (
              <div className="py-5 p-lg-5" style={{ paddingBottom: 42 }}>
                <div className="row align-items-center justify-content-between" style={{ maxWidth: 900, margin: '0 auto' }}>
                  
                  <div className="col">
                    <div className="row row-cols-1 " >
                      {blogs
                        .filter(blog => blog.author && blog.title && blog.authorWork)
                        .map((blog, index) => (
                          <div className="col-xl-12 mb-5" key={index}>
                            <div
                              className="border rounded bg-white shadow" style={{ height: '100%' }}
                            >
                              <div className="card-body px-5 py-4 px-md-5 " style={{ width: '100%', height: '100%' }}>
                                <h5 className="fw-bold card-title text-black">{blog.title}</h5>
                                <h6 className="text-black py-2 mb-0">Author: {blog.author}</h6>
                                {blog.authorWork && (
                                  <p className="fst-italic text-black" style={{ textAlign: 'justify', fontSize: '14px' }}>{blog.authorWork}</p>
                                )}
                                {!expandedBlog && (
                                  <button className="btn btn-primary shadow" onClick={() => toggleExpandedBlog(blog)}>Learn More</button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                  <div className=" mt-3 d-flex justify-content-center">
                  {currentPage > 1 && (
                    <div className="col-auto">
                      <button
                        className="btn shadow"
                        style={{
                          fontWeight: 'bold',
                          fontSize: '23px',
                          color: '#082144',
                          cursor: 'pointer',
                          marginRight: '10px',
                        }}
                        onClick={() => togglePage(currentPage - 1)}
                      >
                        ← Prev
                      </button>
                    </div>
                  )}
                  {currentPage < totalPages && (
                    <div className="col-auto">
                      <button
                        className="btn shadow"
                        style={{
                          fontWeight: 'bold',
                          fontSize: '23px',
                          color: '#082144',
                          cursor: 'pointer',
                          marginLeft: '10px',
                        }}
                        onClick={() => togglePage(currentPage + 1)}
                      >
                        Next →
                      </button>
                    </div>
                  )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-md-8 col-xl-6 text-center mx-auto">
                  <p className="fw-bold mb-2" style={{ color: '#28A745' }}>
                    No blogs available.
                  </p>
                </div>
              </div>
            )}
          </div> 
        </section>
        {expandedBlog && (
          <div className="modal">
            <div className={`newsletter-popup modal-dialog ${pageType === 'newsletter' ? 'modal-dialog-nl' : 'modal-dialog-home'}`}>
              <div className="modal-content">
               {isMobile && (<div className={`close-btn ${pageType === 'newsletter' ? 'close-btn-nl' : 'close-btn-home'}`} onClick={() => toggleExpandedBlog(null)}>×</div>)}
                <div className={`modal-header ${pageType === 'newsletter' ? 'modal-header-nl' : 'modal-header-home'}`}>
                  <h5 className={`modal-title ${pageType === 'newsletter' ? 'modal-title-nl' : 'modal-title-home'}`}>{expandedBlog.title}</h5>
                </div>
                <div className={`modal-body ${pageType === 'newsletter' ? 'modal-body-nl' : 'modal-body-home'}`}>
                  <p className={`fw-bold text-black ${pageType === 'newsletter' ? 'paragraph-spacing-nl' : 'paragraph-spacing-home'}`}>{expandedBlog.author}</p>

                  {expandedBlog.authorWork && (
                    <p className={`fw-bold fst-italic text-black paragraph-spacing ${pageType === 'newsletter' ? 'paragraph-spacing-nl' : 'paragraph-spacing-home'}`}>{expandedBlog.authorWork}</p>
                  )}
                  {expandedBlog.article.split('\n\n').map((paragraph, index) => (
                    <p key={index} className={`paragraph-spacing ${pageType === 'newsletter' ? 'paragraph-spacing-nl' : 'paragraph-spacing-home'}`}>{paragraph}</p>
                  ))}
                  {expandedBlog.reference && (
                    <>
                      <h6 className={`fw-bold text-black ${pageType === 'newsletter' ? 'reference-nl paragraph-spacing-nl' : 'reference-home paragraph-spacing-home'}`}>Reference:</h6>
                      <p className={`reference-text-bottom ${pageType === 'newsletter' ? 'reference-text-bottom-nl' : 'reference-text-bottom-home'}`}>{expandedBlog.reference}</p>
                    </>
                  )}
                </div>
                { !isMobile && 
                <div className="modal-footer">
                  <button type="button" className="btn btn-primary shadow" onClick={() => toggleExpandedBlog(null)}>close</button>
                </div>
}
              </div>
            </div>
          </div>
        )}
      </main>
    </div>
  );
}

export default NL;