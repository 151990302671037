import { useState } from 'react';
import '../styles/conversation.css';

export default function Conversation({conversation, userClicked}) {
    console.log(conversation);
    const [user, setUser] = useState();

    function handleClick(){
        userClicked(conversation)
    }
    return (
        <>
            <div className="conversation" onClick={() => handleClick()}>
                <img
                    src="https://icons.veryicon.com/png/o/internet--web/55-common-web-icons/person-4.png"
                    className="conversationImg"
                />
                <span className="conversationName">{conversation.firstName}</span>
                {/* <span className="conversationTime" style={{marginLeft:'auto'}}>8:30am</span> */}
            </div>
        </>
    );
}
