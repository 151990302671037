import { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {
    Table,
    ScrollArea,
    UnstyledButton,
    Group,
    Text,
    Center,
    TextInput,
    rem,
    keys,
    Container,
} from '@mantine/core';
import {
    IconSelector,
    IconChevronDown,
    IconChevronUp,
    IconSearch,
} from '@tabler/icons-react';
import classes from '../styles/MedicationPage.module.css';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import DatePicker from 'react-datepicker';
import '../styles/index.css';
import { format } from 'date-fns';
import LoginPopup from '../components/LoginPopup';
import ChatBot from '../components/ChatBot';
import { useAuth } from '../providers';
import { IconEdit, IconTrash } from '@tabler/icons-react';

function Th({ children, reversed, sorted, onSort, showSort }) {
    const Icon = sorted
        ? reversed
            ? IconChevronUp
            : IconChevronDown
        : IconSelector;
    return (
        <Table.Th className={classes.th}>
            <UnstyledButton onClick={onSort} className={classes.control}>
                <Group justify="space-between" wrap="nowrap">
                    <Text fw={500} fz="sm">
                        {children}
                    </Text>
                    {showSort && (
                        <Center className={classes.icon}>
                            <Icon
                                style={{ width: rem(16), height: rem(16) }}
                                stroke={1.5}
                            />
                        </Center>
                    )}
                </Group>
            </UnstyledButton>
        </Table.Th>
    );
}

function filterData(data, search) {
    const query = search.toLowerCase().trim();
    return data.filter((item) =>
        Object.keys(item).some((key) =>
            String(item[key]).toLowerCase().includes(query)
        )
    );
}

const API_ROOT = 'https://medharbor-backend.onrender.com/medication';

export default function MedicationPage() {
    const auth = useAuth();
    const [search, setSearch] = useState('');
    const [sortedData, setSortedData] = useState([]);
    const [sortBy, setSortBy] = useState(null);
    const [reverseSortDirection, setReverseSortDirection] = useState(false);
    const [show, setShow] = useState(false);

    const [addMedicineName, setAddMedicineName] = useState('');
    const [addMedicineDosage, setAddMedicineDosage] = useState('');
    const [addMedicineDispensed, setAddMedicineDispensed] = useState('');
    const [addMedicineFrequency, setAddMedicineFrequency] = useState('');
    const [addMedicineLastFilled, setAddMedicineLastFilled] = useState(new Date());
    const [addMedicineLastPrescribed, setAddMedicineLastPrescribed] = useState(new Date());
    const [isPastMedicine, setIsPastMedicine] = useState('');
    const [errors, setErrors] = useState({});
    const [touched, setTouched] = useState({});

    const [showAddMedicationError, setShowAddMedicationError] = useState(false);
    const [medicationsData, setMedicationsData] = useState([]);
    const [editedMedicationId, setEditedMedicationId] = useState(null);
    const [modalMode, setModalMode] = useState('add');
    const userId = auth.user?.id;
    useEffect(() => {
        setSortedData([]);
        fetchMedications();
        if (show && modalMode === 'add') {
            setAddMedicineName('');
            setAddMedicineDosage('');
            setAddMedicineDispensed('');
            setAddMedicineFrequency('');
            setErrors({});
            setTouched({});
        } if(show && modalMode === 'edit'){
            setErrors({});
        }
    }, [userId, show]);

    

    const allFieldsTouched = () => {
        // Check if all fields have been touched
        return touched.name && touched.dosage && touched.dispensed && touched.frequency && touched.isPastMedicine;
    };

    const handleBlur = (event, fieldName) => {
        const { value } = event.target;
        validateField(fieldName, value);
    };

    const validateField = (name, value) => {
        let fieldErrors = { ...errors };
        let isTouched = { ...touched };
        isTouched[name] = true;

        switch (name) {
            case 'name':
                if (!value) {
                    fieldErrors[name] = 'Medicine name is required';
                } else if (!/^[A-Za-z\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Medicine name must contain only alphabets';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'dosage':
                if (!value) {
                    fieldErrors[name] = 'Dosage is required';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Dosage must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'dispensed':
                if (!value) {
                    fieldErrors[name] = 'Total dispensed is required';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Dispensed must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'frequency':
                if (!value) {
                    fieldErrors[name] = 'Frequency is required';
                } else if (!/^[A-Za-z0-9\s]+$/.test(value)) {
                    fieldErrors[name] =
                        'Frequency must not contain special characters';
                } else {
                    delete fieldErrors[name];
                }
                break;
            case 'isPastMedicine':
                if (!value) {
                    fieldErrors[name] = 'Please select an option';
                } else {
                    delete fieldErrors[name];
                }
            default:
                break;
        }

        setErrors(fieldErrors);
        setTouched(isTouched); // Update the touched state
    };

    const handleInputChange = (event, fieldName) => {
        const { value } = event.target;
        switch (fieldName) {
            case 'name':
                setAddMedicineName(value);
                break;
            case 'dosage':
                setAddMedicineDosage(value);
                break;
            case 'dispensed':
                setAddMedicineDispensed(value);
                break;
            case 'frequency':
                setAddMedicineFrequency(value);
                break;
            case 'isPastMedicine':
                setIsPastMedicine(value);
                break;
            default:
                break;
        }
        validateField(fieldName, value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (Object.keys(errors).length === 0) {
            console.log('Form is valid');
            // Submit form logic here
        } else {
            console.log('Form is invalid');
        }
    };

    const isDisabled = () => {
        // Check for any errors in any mode
        if (Object.keys(errors).length > 0) return true;
    
        // Additional checks for the 'add' mode
        if (modalMode === 'add' && !allFieldsTouched()) {
            return true;
        }
    
        // No additional checks needed for 'edit' mode
        return false;
    };

    const fetchMedications = async () => {
        if (!auth || !auth.user || !auth.user.id) {
            console.error("User not authenticated or user id not available");
            return;
        }
        
        const response = await fetch(`${API_ROOT}/${userId}`);
        const data = await response.json();

        console.log("fetch data", data);
        setMedicationsData(data);
        setSortedData(data);
        
    };
    

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setModalMode('add')
        setShow(true);
    }
    const handleSave = () => {
        if (modalMode === 'edit') {
            handleEditSubmit();
        } else {
            addMedication();
        }
    };

    const setSorting = (field) => {
        const reversed = field === sortBy ? !reverseSortDirection : false;
        setSortBy(field);
        setReverseSortDirection(reversed);
        const sortedData = implementSorting(medicationsData, { sortBy: field, reversed});
        setSortedData(sortedData);
    };

    function implementSorting(data, payload) {
        const { sortBy, reversed } = payload;
    
        // Ensure data is copied to avoid mutation
        const copiedData = [...data];
    
        // Sort logic
        const sorted = copiedData.sort((a, b) => {
            // Check if sortBy field is provided; if not, return 0 to indicate no sorting change
            if (!sortBy) return 0;
    
            // Compare values of sortBy field between objects a and b (case-insensitive)
            const comparison = String(a[sortBy]).toLowerCase().localeCompare(String(b[sortBy]).toLowerCase(), undefined, { numeric: true });
    
            // Return comparison result based on reversed flag
            return reversed ? -comparison : comparison;
        });
    
        console.log(sorted); // Log sorted data for debugging
    
        return sorted;
    }
    
    const handleSearchChange = (event) => {
        const { value } = event.currentTarget;
        setSearch(value);

        const sortedAndFilteredData = sortDataNew(medicationsData, {
            sortBy,
            reversed: reverseSortDirection,
            search: value,
        });
        setSortedData(sortedAndFilteredData);
    };


    function sortDataNew(data, payload) {
        const { sortBy, reversed, search } = payload;

        // Filter data based on search query
        const filteredData = filterData(data, search);


        // Sort filteredData based on sortBy field
        const sortedData = [...filteredData].sort((a, b) => {
            if (!sortBy) return 0; // No sorting if sortBy is not provided

            // Handle sorting by sortBy field
            if (reversed) {
                return String(b[sortBy]).localeCompare(String(a[sortBy]), undefined, { numeric: true });
            } else {
                return String(a[sortBy]).localeCompare(String(b[sortBy]), undefined, { numeric: true });
            }
        });
        return sortedData;
    }

    const addMedication = async () => {

        setModalMode('add');

        if (!addMedicineName || !addMedicineDispensed || !addMedicineDosage || !addMedicineFrequency) {
            setShowAddMedicationError(true);
            return;
        }
        // console.log('Before calling API'); // Log a message before calling the API

        const formattedLastFilledDate = addMedicineLastFilled.toISOString().split('T')[0];
        const formattedLastPrescribedDate = addMedicineLastPrescribed.toISOString().split('T')[0];
 

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                userId: auth.user?.id,
                medicationName: addMedicineName,
                dosage: addMedicineDosage,
                dispensed: addMedicineDispensed,
                lastFilledDate: formattedLastFilledDate,
                lastPrescribedDate: formattedLastPrescribedDate,
                frequency: addMedicineFrequency,
                isPastMedicine: isPastMedicine
            })
        };

        const response = await fetch(`${API_ROOT}/createMedication`, requestOptions);

        // console.log('API call successful'); // Log a message after a successful API call
        console.log('API BODY',requestOptions); // Log the response from the API
        const data = await response.json();
        alert(data.message); // Display message in an alert box
        fetchMedications();

        // setSortedData([
        //     {
        //         medicationName: addMedicineName,
        //         dosage: addMedicineDosage,
        //         dispensed: addMedicineDispensed,
        //         lastFilleddate: formattedLastFilledDate,
        //         lastPrescribedDate: formattedLastPrescribedDate,
        //         frequency: addMedicineFrequency,
        //     },
        //     ...sortedData,
        // ]);

        clearAddMedicineForm();
    };

    const clearAddMedicineForm = () => {
        setShowAddMedicationError(false);
        setAddMedicineDispensed('');
        setAddMedicineDosage('');
        setAddMedicineName('');
        handleClose();
    };
    
    const handleEdit = (row) => {
        setModalMode('edit');
        setShow(true);

        // Populate the form fields with the details of the medication being edited
        setEditedMedicationId(row._id);
        setAddMedicineName(row.medicationName);
        setAddMedicineDosage(row.dosage);
        setAddMedicineDispensed(row.dispensed);
        setAddMedicineFrequency(row.frequency);
        setAddMedicineLastFilled(new Date(row.lastFilledDate));
        setAddMedicineLastPrescribed(new Date(row.lastPrescribedDate));
        
        // Show the modal dialog
    };

    const handleEditSubmit = async () => {

        if (!addMedicineName || !addMedicineDispensed || !addMedicineDosage || !addMedicineFrequency) {
            setShowAddMedicationError(true);
            return;
        }

        const formattedLastFilledDate = addMedicineLastFilled.toISOString().split('T')[0];
        const formattedLastPrescribedDate = addMedicineLastPrescribed.toISOString().split('T')[0];
    
        const requestOptions = {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                _id: editedMedicationId,
                medicationName: addMedicineName,
                dosage: addMedicineDosage,
                dispensed: addMedicineDispensed,
                lastFilledDate: formattedLastFilledDate,
                lastPrescribedDate: formattedLastPrescribedDate,
                frequency: addMedicineFrequency,
                isPastMedicine: isPastMedicine
            })
        };
    
        const response = await fetch(`${API_ROOT}/${editedMedicationId}`, requestOptions);
        const data = await response.json();
    
        // Optionally handle response or update UI
        console.log(data);
        fetchMedications();
    
        // Close the modal after successful update
        handleClose();
        alert('Medication Updated Successfully'); // Display message in an alert box
    };

    const handleDelete = async (medicationId) => {
        
        // Send a request to delete the record from the database
        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({

                _id: medicationId
            })
        };

        console.log('Delete request payload:', requestOptions.body)

        const response = await fetch(`${API_ROOT}/${medicationId}`, requestOptions);

        // Update the UI by removing the deleted record from medicationsData
        setMedicationsData(prevData => prevData.filter(item => item._id !== medicationId));

        // Optionally, display a confirmation message
        alert('Medication deleted successfully');
        fetchMedications();
    };



    const rowsCurrent = sortedData
    .filter((row) => !row.isPastMedicine)  
    .map((row) => (
        <Table.Tr key={row._id}>
            <Table.Td>{row.medicationName}</Table.Td>
            <Table.Td>{row.dosage}</Table.Td>
            <Table.Td>{row.dispensed}</Table.Td>
            <Table.Td>{ format(new Date(row.lastFilledDate), 'MM-dd-yyyy')}</Table.Td>
            <Table.Td>{format(new Date(row.lastPrescribedDate), 'MM-dd-yyyy')}</Table.Td>
            <Table.Td>{row.frequency}</Table.Td>
            <Table.Td>
                <div style={{ display: 'flex' }}>
                <IconEdit
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                    onClick={() => handleEdit(row)}
                />
                <IconTrash
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDelete(row._id)}
                />
                </div>
            </Table.Td>
        </Table.Tr>
    ));

    const rowsPast = sortedData
    .filter((row) => row.isPastMedicine)  
    .map((row) => (
        <Table.Tr key={row._id}>
            <Table.Td>{row.medicationName}</Table.Td>
            <Table.Td>{row.dosage}</Table.Td>
            <Table.Td>{row.dispensed}</Table.Td>
            <Table.Td>{ format(new Date(row.lastFilledDate), 'MM-dd-yyyy')}</Table.Td>
            <Table.Td>{format(new Date(row.lastPrescribedDate), 'MM-dd-yyyy')}</Table.Td>
            <Table.Td>{row.frequency}</Table.Td>
            <Table.Td>
                <div style={{ display: 'flex' }}>
                <IconEdit
                    style={{ cursor: 'pointer', marginRight: '10px' }}
                    onClick={() => handleEdit(row)}
                />
                <IconTrash
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDelete(row._id)}
                />
                </div>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <div id="med-page">
            <Container mt="50" pb="50" size="xl">
                <h1>Medication</h1>
                <p>Browse your medication here</p>

                <Tabs
                    defaultActiveKey="current"
                    id="uncontrolled-tab-example"
                    className="mb-3"
                >
                    <Tab eventKey="current" title="Current">
                        <div
                            style={{ display: 'flex', alignItems: 'baseline' }}
                        >
                            <TextInput
                                placeholder="Search..."
                                mb="md"
                                leftSection={
                                    <IconSearch
                                        style={{
                                            width: rem(16),
                                            height: rem(16),
                                        }}
                                        stroke={1.5}
                                    />
                                }
                                value={search}
                                onChange={handleSearchChange}
                                mt={30}
                                mr={20}
                                style={{ flexGrow: 1 }}
                            />
                            <button
                                className="btn btn-primary shadow"
                                type="button"
                                onClick={handleShow}
                            >
                                Add medicine
                            </button>
                        </div>
                        <Table
                            horizontalSpacing="lg"
                            verticalSpacing="xs"
                            miw={700}
                        >
                            <Table.Tbody>
                                <Table.Tr>
                                    <Th
                                        sorted={sortBy === 'name'}
                                        reversed={reverseSortDirection}
                                        onSort={() => setSorting('medicationName')}
                                        showSort
                                    >
                                        Name
                                    </Th>
                                    <Th
                                        //   sorted={sortBy === 'dosage'}
                                        //   reversed={reverseSortDirection}
                                        //   onSort={() => setSorting('dosage')}
                                        showSort={false}
                                    >
                                        Dosage
                                    </Th>
                                    <Th
                                        //   sorted={sortBy === 'dispensed'}
                                        //   reversed={reverseSortDirection}
                                        //   onSort={() => setSorting('dispensed')}
                                        showSort={false}
                                    >
                                        Dispensed
                                    </Th>
                                    <Th
                                        sorted={sortBy === 'lastFilled'}
                                        reversed={reverseSortDirection}
                                        onSort={() => setSorting('lastFilledDate')}
                                        showSort

                                    >
                                        <div style={{textWrap: "nowrap"}}>
                                            Last filled
                                        </div>

                                    </Th>
                                    <Th
                                        sorted={sortBy === 'lastPrescribed'}
                                        reversed={reverseSortDirection}
                                        onSort={() =>
                                            setSorting('lastPrescribedDate')
                                        }
                                        showSort
                                        horizontalSpacing="xs"
                                    >
                                        Last Prescribed
                                    </Th>
                                    <Th
                                        // sorted={sortBy === 'lastPrescribed'}
                                        // reversed={reverseSortDirection}
                                        // onSort={() => setSorting('lastPrescribed')}
                                        showSort={false}
                                    >
                                        Frequency
                                    </Th>
                                </Table.Tr>
                            </Table.Tbody>
                            <Table.Tbody>
                                {rowsCurrent.length > 0 ? (
                                    rowsCurrent
                                ) : (
                                    <Table.Tr>
                                        <Table.Td
                                            colSpan={Object.keys([0]).length}
                                        >
                                            <Text fw={500} ta="center">
                                                Nothing found
                                            </Text>
                                        </Table.Td>
                                    </Table.Tr>
                                )}
                            </Table.Tbody>
                        </Table>

                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>Add medicine</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Form>
                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Medicine name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={addMedicineName}
                                            placeholder="Enter medicine"
                                            onChange={(e) =>
                                                handleInputChange(e, 'name')
                                            }
                                            onBlur={(e) =>
                                                handleBlur(e, 'name')
                                            }
                                            isInvalid={!!errors.name}
                                        />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Dosage</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={addMedicineDosage}
                                            placeholder="Enter dosage"
                                            onChange={(e) =>
                                                handleInputChange(e, 'dosage')
                                            }
                                            onBlur={(e) =>
                                                handleBlur(e, 'dosage')
                                            }
                                            isInvalid={!!errors.dosage}
                                        />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.dosage}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                    >
                                        <Form.Label>Dispensed</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={addMedicineDispensed}
                                            placeholder="Enter total dispensed"
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    'dispensed'
                                                )
                                            }
                                            onBlur={(e) =>
                                                handleBlur(e, 'dispensed')
                                            }
                                            isInvalid={!!errors.dispensed}
                                        />
                                          <Form.Control.Feedback type="invalid">
                                            {errors.dispensed}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Last filled</Form.Label>{' '}
                                        <br />
                                        <DatePicker
                                            selected={addMedicineLastFilled}
                                            onChange={(date) =>
                                                setAddMedicineLastFilled(date)
                                            }
                                            style={{ width: '100%' }}
                                            id="addMedicineLastFilled"
                                        />
                                    </Form.Group>

                                    <Form.Group className="mb-3">
                                        <Form.Label>Last prescribed</Form.Label>{' '}
                                        <br />
                                        <DatePicker
                                            selected={addMedicineLastPrescribed}
                                            onChange={(date) =>
                                                setAddMedicineLastPrescribed(
                                                    date
                                                )
                                            }
                                            style={{ width: '100%' }}
                                            id="addMedicineLastPrescribed"
                                        />
                                    </Form.Group>

                                    

                                    <Form.Group className="mb-3">
                                        <Form.Label>Frequency</Form.Label>{' '}
                                        <br />
                                        <Form.Control
                                            type="text"
                                            value={addMedicineFrequency}
                                            placeholder="Enter frequency"
                                            onChange={(e) =>
                                                handleInputChange(
                                                    e,
                                                    'frequency'
                                                )
                                            }
                                            onBlur={(e) =>
                                                handleBlur(e, 'frequency')
                                            }
                                            isInvalid={!!errors.frequency}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.frequency}
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formIsPastMedicine">
                                        <Form.Label>Is this a past medicine?</Form.Label>
                                        <Form.Select
                                            value={isPastMedicine}
                                            onChange={(e) => handleInputChange(e, 'isPastMedicine')}
                                            onBlur={(e) => handleBlur(e, 'isPastMedicine')}
                                            isInvalid={!!errors.isPastMedicine}
                                        >
                                            <option value="" selected>Select</option>
                                            <option value="yes">Yes</option>
                                            <option value="no">No</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.isPastMedicine}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Form>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button
                                    variant="primary"
                                    // onClick={modalMode === 'edit' ? handleEditSubmit : addMedication}
                                    disabled={isDisabled()}
                                    onClick={handleSave}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant="secondary"
                                    onClick={handleClose}
                                >
                                    Close
                                </Button>
                                
                            </Modal.Footer>
                        </Modal>
                    </Tab>
                    <Tab eventKey="past" title="Past">
                    <Table
                            horizontalSpacing="lg"
                            verticalSpacing="xs"
                            miw={700}
                        >
                            <Table.Tbody>
                                <Table.Tr>
                                    <Th
                                        sorted={sortBy === 'name'}
                                        reversed={reverseSortDirection}
                                        onSort={() => setSorting('medicationName')}
                                        showSort
                                    >
                                        Name
                                    </Th>
                                    <Th
                                        //   sorted={sortBy === 'dosage'}
                                        //   reversed={reverseSortDirection}
                                        //   onSort={() => setSorting('dosage')}
                                        showSort={false}
                                    >
                                        Dosage
                                    </Th>
                                    <Th
                                        //   sorted={sortBy === 'dispensed'}
                                        //   reversed={reverseSortDirection}
                                        //   onSort={() => setSorting('dispensed')}
                                        showSort={false}
                                    >
                                        Dispensed
                                    </Th>
                                    <Th
                                        sorted={sortBy === 'lastFilled'}
                                        reversed={reverseSortDirection}
                                        onSort={() => setSorting('lastFilledDate')}
                                        showSort

                                    >
                                        <div style={{textWrap: "nowrap"}}>
                                            Last filled
                                        </div>

                                    </Th>
                                    <Th
                                        sorted={sortBy === 'lastPrescribed'}
                                        reversed={reverseSortDirection}
                                        onSort={() =>
                                            setSorting('lastPrescribedDate')
                                        }
                                        showSort
                                        horizontalSpacing="xs"
                                    >
                                        Last Prescribed
                                    </Th>
                                    <Th
                                        // sorted={sortBy === 'lastPrescribed'}
                                        // reversed={reverseSortDirection}
                                        // onSort={() => setSorting('lastPrescribed')}
                                        showSort={false}
                                    >
                                        Frequency
                                    </Th>
                                </Table.Tr>
                            </Table.Tbody>
                            <Table.Tbody>
                                {rowsPast.length > 0 ? (
                                    rowsPast
                                ) : (
                                    <Table.Tr>
                                        <Table.Td
                                            colSpan={Object.keys([0]).length}
                                        >
                                            <Text fw={500} ta="center">
                                                Nothing found
                                            </Text>
                                        </Table.Td>
                                    </Table.Tr>
                                )}
                            </Table.Tbody>
                        </Table>

                    </Tab>
                </Tabs>
            </Container>
            {(!auth || !auth.user) && <LoginPopup />}
            {/* ChatBot Component */}
      <div style={{
        position: 'fixed',
        bottom: '20px',
        right: '20px',
        zIndex: 1000
      }}>
        <ChatBot />
      </div>
        </div>
    );
}