import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import image from '../Assets/chatbot.png';
import sendImage from '../Assets/send.png';
import '../styles/Chatbot.css';

const ChatBot = () => {
  const [show, setShow] = useState(false);
  const [showBubble, setShowBubble] = useState(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);

  const handleSendMessage = (event) => {
    event.preventDefault();
    // Handle sending the message (you might want to store it or send it to the bot)
    const message = event.target.messageInput.value;
    console.log("Message sent:", message);
    // Clear the input field after sending
    event.target.messageInput.value = '';
  };

  useEffect(() => {
    // Show the chat bubble for 5 seconds on page load
    const timer = setTimeout(() => {
      setShowBubble(false);
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {/* Chat bubble that pops up for a few seconds */}
      {showBubble && (
        <div className="chat-bubble">
          <p>Hello! Looking for something?</p>
        </div>
      )}

      {/* Chatbot icon */}
      <div className="chatbot-icon" onClick={handleShow}>
        <img 
          src={image} 
          alt="ChatBot" 
          style={{ width: '65px', cursor: 'pointer' }} 
        />
      </div>

      {/* Chatbot modal */}
      <div className={`chatbot-modal ${show ? 'active' : ''}`}>
        <div className="chatbot-header">
          <div>MedHarbor Bot</div>
          <Button className="close-btn" onClick={handleShow}>X</Button>
        </div>
        <div className="chatbot-body">
          <div className="chat-message">
            <strong>Bot:</strong> Hello! How can I assist you today?
          </div>
          <Form onSubmit={handleSendMessage}>
            <div className="input-container">
              <Form.Group controlId="messageInput" className="flex-grow-1">
                <Form.Control 
                  type="text" 
                  placeholder="Type your message..." 
                  name="messageInput"
                  style={{ border: 'none', boxShadow: 'none' }} // No outline or shadow
                />
              </Form.Group>
              <img 
                src={sendImage} 
                alt="Send" 
                className="send-image" 
                onClick={handleSendMessage} 
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChatBot;
